
import React from 'react'
import "./lines2.css"

const Lines2 = () => {

  const style =
    {
    margin: "5px auto",
    alignSelf: "center",
    maxWidth: "75%",

    }



  return (
    <>
    
    <div className="line-container-2">
        <hr className="line-2"/>
        <hr className="thin-2"/>
        <hr className="line-dotted-2" />
    </div>
        
      
    </>
  )
}

export default Lines2;

export const MenuItems = 
[

    {
       title: 'Angličtina Dospelí',
       path: '/ang-dospeli',
       cName: 'dropdown-link'     
    },
    {
       title: 'Angličtina Deti',
       path: '/ang-deti',
       cName: 'dropdown-link'     
    },
    {
       title: 'Tvorivá dieľňa',
       path: '/tvoriva-dielna',
       cName: 'dropdown-link'     
    }




]

export default MenuItems;